import React from 'react'
import PromotionCategory from 'templates/promotion-category'
import { graphql } from 'gatsby'

const CCEPromotions = props => {
  return <PromotionCategory {...props} category='compact-construction-equipment' />
}

export const pageQuery = graphql`
  {
    allHutsonPromotion(
      filter: { tags: { in: ["compact-construction-equipment"] } }
      sort: { fields: [endDate], order: ASC }
    ) {
      nodes {
        endDate
        endDateString
        excerpt
        id
        image: localImage {
          ...SharpImage640
        }
        link
        slug
        tags
        title
      }
    }
    allDeereOffer(
      sort: { fields: [endDate], order: ASC }
      filter: {
        subcategoryList: { in: ["track-loaders", "excavators", "skid-steers", "wheel-loaders"] }
      }
    ) {
      nodes {
        endDate
        endDateString: endDate(formatString: "MMMM DD, YYYY")
        excerpt
        id
        image: localImage {
          ...SharpImage640
        }
        slug
        subTitle
        tags
        title
      }
    }
  }
`

export default CCEPromotions
